import React from 'react';
import DeleteIcon from "@material-ui/icons/Delete";

const DeleteIconRenderer = (params) => (
    <div>
      <DeleteIcon
        onClick={(e) => {
          deleteFocusArea(params);
        }}
      />
    </div>
  );
export default DeleteIconRenderer;