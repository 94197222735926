import React from 'react';
import EditIcon from "@material-ui/icons/Edit";

const EditIconRenderer = (params) => (
    <div>
      <EditIcon 
        onClick={(e) => {
         editDetails(e, params);
        }}
        />
      </div>
    );
export default EditIconRenderer;