import React from 'react';
import ChevronRightSharpIcon from "@material-ui/icons/ChevronRightSharp";

const ViewIconRenderer = (params) => (
    <div>
      <ChevronRightSharpIcon
        /*onClick={(e) => {
          viewDetails(e, params);
        }}*/
      />
    </div>
  );
export default ViewIconRenderer;