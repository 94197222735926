import React, { useState, useEffect, useRef } from "react";
import { useHistory, useLocation, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import Tabs from "@material-ui/core/Tabs";
import { withStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import "./index.css";
import { connect } from "react-redux";
import { isMobile, isTablet, isDesktop} from 'react-device-detect';
import DrawerComponent from "./DrawerComponent/DrawerComponent";
import "bootstrap/dist/css/bootstrap.min.css";
import logoIcon from "../../assets/images/DNV_logo_RGB.png";
import logoIconDark from "../../assets/images/DNV_logo_white_RGB.png";

const TabsList = [
  {
    name: "Home",
    path: "/home",
  },
  { name: "Focus area ecosystem", path: "/focus-area" },
  { name: "Propose a focus area", path: "/proposals" },
];

const StyledTabs = withStyles({
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    color: "#ffffff",
    "& > span": {
      // maxWidth: 40,
      width: "100%",
      color: "#ffffff",
      backgroundColor: "#635ee7",
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    color: "#fff",
    opacity: 1,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    "&:focus": {
      opacity: 1,
    },
    "&$selected": {
      opacity: 1,
      color: "#fff",
    },
  },
}))((props) => <Tab disableRipple {...props} />);

export const Header = (props) => {
  const { i18n, t } = useTranslation()

  const [selectedLang, setSelectedLang] = useState({value: 'en', label: 'English'})
  const history = useHistory();
  const [country, setCountry] = useState(false)
  const location = useLocation();
  const [value, setValue] = useState(history.location.pathname);
  const ref = useRef()
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!ref.current.contains(event.target)) {
        setCountry(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
  }, [ref]);
  useEffect(() => {
    setValue(history.location.pathname);
  }, [location]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    history.push(newValue);
  };
  const loggedInUserName = props.userInfo.given_name
    ? props.userInfo.given_name + "," + props.userInfo.family_name
    : "test user";
  const langs = props.langs?.data[0].availableLang
  const loggedInUserShortName = props.userInfo.given_name
    ? props.userInfo.given_name.charAt(0) +
      "" +
      props.userInfo.family_name.charAt(0)
    : "TU";
  let isAdmin = false;
  const logOutURL = process.env.REACT_APP_LOGOUT_URL;
  const users = process.env.REACT_APP_ADMIN_PERMISSIONS.split(" ");
  if (
    props.userInfo.email &&
    props.userInfo.email.length > 0 &&
    users.includes(props.userInfo.email[0])
  ) {
    isAdmin = true;
  }
  if (isAdmin && !TabsList[3]) {
    TabsList.push({ name: "Admin", path: "/admin" });
  }
  const onLangChange = (value) => {
    setCountry((prev) => !prev);
    i18n.changeLanguage(value)
    //console.log('onLangChange',event.target.value)
    /* setSelectedLang(selectedData)
    i18n.changeLanguage(selectedData.value) */
  }
  const handleCountryChange = () => {
    setCountry((prev) => !prev);
  }
  return (
    <div className={value === "/focus-area" ? "header-dark" : "header"}>
      <div className="flex-row headerlogo" id="headerMain">
        <div className="logo">
          <NavLink className="logoImg" to="/home">
            <img src={value === "/focus-area" ? logoIconDark : logoIcon} />
          </NavLink>
          <div className="float-right paddingRight textwidth">
            <ul className="navbar-nav ml-auto mt-2 mt-lg-0 nav nav-tabs textspace">
              <li
                className={
                  value === "/focus-area"
                    ? "nav-item tab text-color-dark"
                    : "nav-item tab text-color"
                }
              >
                {t('BOOST MY AUDIT')} &nbsp;&nbsp;|
              </li>
              <span ref={ref}>
              <li className="nav-item mr-auto">
              <a className={value === "/focus-area"? "nav-link pr-0 globeCountry white": "nav-link pr-0 globeCountry dark"} title={'Languages List'} onClick={handleCountryChange}>
                <i className="fa fa-globe"></i>
                </a>
              </li>
              {country && 
                  <div className="region-selection__nav">
                     {langs.map((item, key) => {
                return (
                    <li className="region-select" key={key} value={item.value} onClick={() => onLangChange(item.value)}>{item.label}</li>
                );
              })}
                  </div>}
                  </span>
              <li className="nav-item mr-auto">
                <a className="nav-link pr-0" title={loggedInUserName}>
                  <span
                    className={
                      value === "/focus-area" ? "circle" : "circle-dark"
                    }
                    aria-hidden="true"
                  >
                    {loggedInUserShortName}
                  </span>
                </a>
              </li>
              <li className="nav-item mr-auto LogoutUrl">
                <a href={logOutURL}>
                  <button type="submit" className="btn btn-link btn-logout">
                  {t('Logout')}
                  </button>
                </a>
              </li>
              <li className="nav-item mr-auto HamburgerMenu">
              <span className="seprtr">|&nbsp;&nbsp;</span>{<DrawerComponent />}
              </li>
            </ul>
          </div>
        </div>
        <div className="headerNav">
          {value === "/focus-area" ? (
            <StyledTabs
              value={value}
              textColor="primary"
              onChange={handleChange}
              aria-label="styled tabs example"
              className={
                value === "/focus-area"
                  ? "MuiTabs-root selectedTextColorDark"
                  : "MuiTabs-root selectedTextColor"
              }
            >
              {TabsList.map((item, key) => {
                return (
                  <StyledTab label={t(item.name)} key={key} value={item.path} />
                );
              })}
            </StyledTabs>
          ) : (
            <Tabs
              value={value}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleChange}
              aria-label="styled tabs example"
            >
              {TabsList.map((item, key) => {
                return <Tab label={t(item.name)} key={key} value={item.path} />;
              })}
            </Tabs>
          )}
        </div>
      </div>
    </div>
  );
};
export default connect(null, null)(Header);
