import React, { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { AgGridReact } from "ag-grid-react";
//import { AgGridColumn } from "@ag-grid-community/react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import CheckIcon from "@material-ui/icons/Check";
import ChevronRightSharpIcon from "@material-ui/icons/ChevronRightSharp";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import axios from "axios";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import Grid from "ag-grid-community";
import { LicenseManager } from  'ag-grid-enterprise'
import "./index.css";
import ProposalModal from "../../components/ProposalModal";
import { appInsights } from "../../AppInsights";
import EditIconRenderer from "./editIconRenderer";
import DeleteIconRenderer from "./deleteIconRenderer";
import ViewIconRenderer from "./viewIconRenderer";

LicenseManager.setLicenseKey(
  //"CompanyName=DNV AS,LicensedGroup=nxtGen Quoting app,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=2,LicensedProductionInstancesCount=1,AssetReference=AG-036039,SupportServicesEnd=20_February_2024_[v2]_MTcwODM4NzIwMDAwMA==2f056a01cada92d71a4898145d507960"
  'Using_this_{AG_Grid}_Enterprise_key_{AG-052160}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{DNV_AS}_is_granted_a_{Multiple_Applications}_Developer_License_for_{2}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_{AG_Grid}_Enterprise___This_key_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{19_February_2025}____[v3]_[01]_MTczOTkyMzIwMDAwMA==6e91502a972253c69d73a80087b2a5b5'
);

const AdminView = (props) => {
  const { i18n } = useTranslation()
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [rowData, setRowData] = useState();
  const [activeRecordParams, setActiveRecordParams] = useState({});
  const [selectedTab, setSelectedTab] = useState("FOCUSAREAECOSYSTEM");
  const [submit, setSubmit] = useState(false);
  const [edit, setEdit] = useState(false);
  const [newFA, setNewFA] = useState(true);
  const [readonlyFA, setReadOnlyFA] = useState(false);

  const TabsList = [
    { name: "FA ecosystem", path: "FOCUSAREAECOSYSTEM" },
    { name: "Proposed FA", path: "PROPOSEDFOCUSAREA" },
  ];

  const columnDefs = [
    {
      headerName: "Macro process",
      field: "Macro_process",
      sortable: true,
      filter: true,
    },
    {
      headerName: "Enterprise risk",
      field: "Enterprise_Risk",
      sortable: true,
      filter: true,
    },
    {
      headerName: "Release",
      field: "Release",
      sortable: true,
      filter: true,
    },
    {
      headerName: "Focus areas title",
      field: "Focus_Areas",
      sortable: true,
      filter: true,
    },
    {
      headerName: "Plan",
      field: "plan",
      sortable: true,
      filter: true,
      hide: true,
    },
    {
      headerName: "do",
      field: "do",
      sortable: true,
      filter: true,
      hide: true,
    },
    {
      headerName: "Check",
      field: "check",
      sortable: true,
      filter: true,
      hide: true,
    },
    {
      headerName: "Check",
      field: "check",
      sortable: true,
      filter: true,
      hide: true,
    },
    {
      headerName: "act",
      field: "act",
      sortable: true,
      filter: true,
      hide: true,
    },
  ];
  const gridOptions = {
    columnDefs: columnDefs,
    defaultColDef: {
      filter: true,
      sortable: true,
      resizable: true,
    },
  };
    /* colDefs FA ecosystem starts */
  const colDefsFAE = [
    { field: "faId", cellDataType: "text", headerName: "FA ID" },
    { field: "Standard", cellDataType: "text" },
    { field: "EA_Sector", cellDataType: "text", headerName: "EA Sector" },
    { field: "SDG", cellDataType: "text", headerName: "SDG" },
    { field: "ESG", cellDataType: "text", headerName: "ESG" },
    { field: "Macro_process", cellDataType: "text", headerName: "Macro process" },
    { field: "Enterprise_Risk", cellDataType: "text", headerName: "Enterprise risk" },
    { field: "Release", cellDataType: "text", headerName: "Release" },
    { field: "Focus_Areas", cellDataType: "text", headerName: "Focus area" },
    { field: "lang", cellDataType: "text",headerName: "Language" },
    { field: "",
      cellRenderer: (params) => {
        return (
          <div>
              <EditIcon
              onClick={(e) => {
         editDetails(e, params);
        }}
              />
          
          </div>
        );
      },
      menuTabs: []
    }
  ]
  /* colDefs FA ecosystem end */
  /* colDefs Propposed FA start */
  const colDefsPropsedFA = [
    { field: "faId", cellDataType: "text", headerName: "FA ID" },
    { field: "Standard", cellDataType: "text" },
    { field: "EA_Sector", cellDataType: "text", headerName: "EA Sector" },
    { field: "SDG", cellDataType: "text", headerName: "SDG" },
    { field: "ESG", cellDataType: "text", headerName: "ESG" },
    { field: "Macro_process", cellDataType: "text", headerName: "Macro process" },
    { field: "Enterprise_Risk", cellDataType: "text", headerName: "Enterprise risk" },
    { field: "Release", cellDataType: "text", headerName: "Release" },
    { field: "Focus_Areas", cellDataType: "text", headerName: "Focus areas" },
    { field: "lang", cellDataType: "text", headerName: "Language" },
    {
      field: "",
      cellRenderer: (params) => {
        return (
          <div>
              <DeleteIcon
                onClick={(e) => {
                  deleteFocusArea(params);
                }}
              />
          </div>
        );
      },
      menuTabs: []
    },
    {
      field: "",
      cellRenderer: "viewIconRenderer",
      onCellClicked: (params) => {
        viewDetails(params);
      },
      menuTabs: []
    }
  ]
  
  const [colDefs,setColDefs] = useState(colDefsFAE);
  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      flex:1,
      // headerComponentParams: {
      //   menuIcon: 'fa-bars',
      // },
      suppressMenuHide: false,
      suppressHeaderContextMenu:false
    };
  }, []);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
    if(newValue === "PROPOSEDFOCUSAREA"){
      setColDefs(colDefsPropsedFA);
    }else if(newValue === "FOCUSAREAECOSYSTEM"){
      setColDefs(colDefsFAE);
    }
  };

  const onFirstDataRendered = (params) => {
    params.api.sizeColumnsToFit();
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const getDataAsArray = (data) => {
    const newArr = [];
    data.map((item) => {
      newArr.push(item.value);
    });
    return newArr;
  };

  const formatRowData = (data) => {
    //console.log("Ani in da",data);
    return data.map((item, i) => {
      return {
        ...item,
        Standard: getDataAsArray(item.standard),
        EA_Sector: getDataAsArray(item.eaSector),
        //sdg: getDataAsArray(item.sdg),
        //esg: getDataAsArray(item.esg),

        SDG: item.sdg?item.sdg.length>0?getDataAsArray(item.sdg):'-':'-',
        ESG: item.esg?item.esg.length>0?getDataAsArray(item.esg):'-':'-',
        Title_Langauage: item.FATitle[0].Language,
        Macro_process:
          item.macroprocess.length > 0 ? item.macroprocess[0].value : "",
        Enterprise_Risk:
          item.enterpriseRisk.length > 0 ? item.enterpriseRisk[0].value : "",
        Release: item.release,
        Focus_Areas:
          item.FATitle ? item.FATitle : '',
        Focus_Areas_Rationale: item.faRational,
        plan: item.plan.length > 0 ? item.plan : [],
        do: item.do.length > 0 ? item.do : [],
        check: item.check.length > 0 ? item.check : [],
        act: item.act.length > 0 ? item.act : [],
      };
    });
  };

  const clearFilters = () => {
    gridApi.setFilterModel(null);
  };

  useEffect(() => {
    loadAdminData(selectedTab);
  }, [selectedTab]);

  const loadAdminData = (selectedTab) => {
    gridApi?.showLoadingOverlay();
    var url;
    if (selectedTab.toUpperCase() === "FOCUSAREAECOSYSTEM") {
      url = `${process.env.REACT_APP_API_URL}/getAllFocusAreaLibrary?lang=${i18n.language}`;
    } else if (selectedTab.toUpperCase() === "PROPOSEDFOCUSAREA") {
      url = `${process.env.REACT_APP_API_URL}/getAllProposedFA?lang=${i18n.language}`;
    }
    const options = {
      method: "GET",
      headers: {
        Authorization: sessionStorage.getItem("access_token"),
        "Ocp-Apim-Subscription-Key": `${process.env.REACT_APP_Ocp_Apim_Subscription_Key}`,
      },

      url: url,
    };
    axios(options)
      .then((response) => {
        setRowData(formatRowData(response.data));
      })
      .catch((error) => {
        appInsights.trackException({
          error: new Error("API error"),
          severityLevel: "high",
        });
        setRowData(formatRowData([]));
      });
      
  };

  const editDetails = (e,params) => {
    setActiveRecordParams(params.data);
    setSubmit(true);
    setEdit(true);
    setReadOnlyFA(false);
    setOpen(true);
  };

  const closeFocusAreaModal = () => {
    setOpen(false);
  };

  const deleteFocusArea = (params) => {
    const options = {
      method: "POST",
      headers: {
        Authorization: sessionStorage.getItem("access_token"),
        "Ocp-Apim-Subscription-Key": `${process.env.REACT_APP_Ocp_Apim_Subscription_Key}`,
      },
      data: {
        faId: params.data.faId,
        lang: params.data.lang
      },
      url: `${process.env.REACT_APP_API_URL}/deleteProposedFocusArea`,
    };
    axios(options)
      .then((response) => {
        toast(response.data);
        loadAdminData("PROPOSEDFOCUSAREA");
      })
      .catch((error) => {
        toast(error);
        appInsights.trackException({
          error: new Error("API error deleteFocusArea"),
          severityLevel: "high",
        });
      });
    //console.log("deleting a focus area");
  };

  const viewDetails = (params) => {
    setActiveRecordParams(params.data);
    setSubmit(false);
    setEdit(false);
    setNewFA(false);
    setReadOnlyFA(true);
    setOpen(true);
  };

  const editIconRenderer = (params) => (
    <div
      onClick={(e) => {
        editDetails(e, params);
        //console.log("Ani in params",params);
      }}
    >
      <EditIcon />
    </div>
  );

  /*const deleteIconRenderer = (params) => (
    <div>
      <DeleteIcon
        onClick={(e) => {
          deleteFocusArea(params);
        }}
      />
    </div>
  );*/

  const approveIconRenderer = (params) => (
    <div>
      <CheckIcon />
    </div>
  );

  /*  const viewIconRenderer = (params) => (
    <div>
      <ChevronRightSharpIcon
        onClick={(e) => {
          viewDetails(e, params);
        }}
      />
    </div>
  );*/

  const addNewFA = () => {
    //rowData.exportDataAsCsv();
    setEdit(false);
    setNewFA(true);
    setReadOnlyFA(false);
    setSubmit(true);
    setOpen(true);
  };

  function getParams() {
    return { allColumns: true };
  }

  const onBtnExport = () => {
    var params = getParams();
    gridApi.exportDataAsCsv(params);
  };

  return (
    <div>
      <div className="adminView">
        <div className="admin-tab">
          <Tabs
            value={selectedTab}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChange}
            aria-label="styled tabs example"
          >
            {TabsList.map((item, key) => {
              return (
                <Tab label={item.name} key={key} id={key} value={item.path} />
              );
            })}
          </Tabs>
        </div>

        {selectedTab === "FOCUSAREAECOSYSTEM" && (
          <div>
            <button onClick={() => clearFilters()} className="resetFilter">
              Reset Filters
            </button>
            <button onClick={() => addNewFA()} className="addNewFA">
              Add new FA
            </button>
          </div>
        )}

        {selectedTab === "PROPOSEDFOCUSAREA" && (
          <button onClick={() => onBtnExport()} className="addNewFA">
            Export data
          </button>
        )}
        <div className="ag-theme-alpine">
          <AgGridReact
            onGridReady={onGridReady}
            onFirstDataRendered={onFirstDataRendered}
            columnDefs={colDefs}
            suppressMenuHide={true}
            // paginationAutoPageSize={true}  // Automatically show records in each page as it can fit
            pagination={true}
            paginationPageSizeSelector = {false}
            paginationPageSize={10} // To set 10 records per page
            defaultColDef={defaultColDef}
            rowData={rowData}
            columnMenu = {'legacy'}
            components={{
              editIconRenderer: EditIconRenderer,
              deleteIconRenderer: DeleteIconRenderer,
              approveIconRenderer: approveIconRenderer,
              viewIconRenderer: ViewIconRenderer,
            }}
          >
            {/*<AgGridColumn
              headerName="FA ID"
              field="faId"
              sortable={true}
              filter={true}
              resizable={true}
              //width={'100%'}
            ></AgGridColumn>
            <AgGridColumn
              headerName="Standard"
              field="Standard"
              sortable={true}
              filter={true}
              resizable={true}
              //width={'150%'}
            ></AgGridColumn>
            <AgGridColumn
              headerName="EA Sector"
              field="EA_Sector"
              sortable={true}
              filter={true}
              resizable={true}
              //width={'150%'}
            ></AgGridColumn>
            <AgGridColumn
              headerName="SDGs (Sust. Dev. Goals)"
              field="SDG"
              sortable={true}
              filter={true}
              resizable={true}
              //width={'150%'}
            ></AgGridColumn>
            <AgGridColumn
              headerName="ESG"
              field="ESG"
              sortable={true}
              filter={true}
              resizable={true}
              //width={'150%'}
            ></AgGridColumn>*/}

            {/* <AgGridColumn
            headerName="Title language"
            field="Title_Langauage"
            sortable={true}
            filter={true}
            maxWidth={150} 
          ></AgGridColumn> */}
            {/*<AgGridColumn
              headerName="Macro process"
              field="Macro_process"
              sortable={true}
              filter={true}
              resizable={true}
              //width={'160%'}
            ></AgGridColumn>
            <AgGridColumn
              headerName="Enterprise risk"
              field="Enterprise_Risk"
              sortable={true}
              filter={true}
              resizable={true}
              //width={'160%'}
            ></AgGridColumn>
            <AgGridColumn
              headerName="Release"
              field="Release"
              sortable={true}
              filter={true}
              resizable={true}
              //width={'110%'}
            ></AgGridColumn>
            <AgGridColumn
              headerName="Focus areas title"
              field="Focus_Areas"
              sortable={true}
              filter={true}
              resizable={true}
              //width={'300%'}
            ></AgGridColumn>
             <AgGridColumn
              headerName="Language"
              field="lang"
              sortable={true}
              filter={true}
              resizable={true}
              //width={'300%'}
            ></AgGridColumn>
            <AgGridColumn
              headerName="Rationale"
              field="Focus_Areas_Rationale"
              sortable={true}
              filter={true}
              resizable={true}
              hide={true}
              //width={'300%'}
            ></AgGridColumn>
            <AgGridColumn
              headerName="Plan"
              field="plan"
              sortable={true}
              filter={true}
              resizable={true}
              hide={true}
              //width={'300%'}
            ></AgGridColumn>
            <AgGridColumn
              headerName="do"
              field="do"
              sortable={true}
              filter={true}
              resizable={true}
              hide={true}
              //width={'300%'}
            ></AgGridColumn>
            <AgGridColumn
              headerName="Check"
              field="check"
              sortable={true}
              filter={true}
              resizable={true}
              hide={true}
              //width={'300%'}
            ></AgGridColumn>
            <AgGridColumn
              headerName="act"
              field="act"
              sortable={true}
              filter={true}
              resizable={true}
              hide={true}
              //width={'300%'}
            ></AgGridColumn>
            {selectedTab === "FOCUSAREAECOSYSTEM" && (
              <AgGridColumn
                headerNamefield="edit"
                sortable={true}
                filter={true}
                resizable={true}
                cellRenderer="editIconRenderer"
                //width={'190%'}
              ></AgGridColumn>
            )}
            {selectedTab === "PROPOSEDFOCUSAREA" && (
              <AgGridColumn
                headerNamefield="Delete"
                cellRenderer="deleteIconRenderer"
                suppressSizeToFit={true}
                width={50}
                resizable={true}
              ></AgGridColumn>
            )}*/}
            {/* {selectedTab === "PROPOSEDFOCUSAREA" && (
          <AgGridColumn
            headerNamefield="Approve"
            sortable={true}
            filter={true}
            cellRenderer="approveIconlRenderer"
            width={80}
          ></AgGridColumn> )} */}
            {/*{selectedTab === "PROPOSEDFOCUSAREA" && (
              <AgGridColumn
                headerNamefield="view"
                sortable={true}
                filter={true}
                resizable={true}
                cellRenderer="viewIconRenderer"
                // width={'100%'}
            ></AgGridColumn>
            )}*/}
          </AgGridReact>
          <div>
            <ProposalModal
              open={open}
              activeRecordParams={activeRecordParams}
              handleClose={closeFocusAreaModal}
              userInfo={props.userInfo}
              edit={edit}
              newFA={newFA}
              readonlyFA={readonlyFA}
              submitable={submit}
              loadAdminData={loadAdminData}
              langInfo={props.langInfo}
              rowData={rowData}
            ></ProposalModal>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  //console.log('state', state)
  return {
    isAuthUser: state.login.isAuthUser || false,
    userInfo: state.login.userInfo,
    langInfo: state.login.langInfo
  };
}


export default connect(mapStateToProps, null)(AdminView);
